import request from '@/utils/requestV2'
const qs = require('qs')

/**
 *采购单个资源(可能来自于svg或列表)
 */
export function addResource (data) {
  return request({
    url: '/ooh-order/v1/purchase/addresource',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
   *删除资源（非固定明细和固定明细）
   */
export function deleteResource (data) {
  return request({
    url: '/ooh-order/v1/purchase/deleteresource',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 *替换资源
*/
export function replaceResource (data) {
  return request({
    url: '/ooh-order/v1/purchase/replaceresource',
    method: 'post',
    data: qs.stringify(data)
  })
}
