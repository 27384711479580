import request from '@/utils/requestV2'
const qs = require('qs')

// 获取资源位置
export function getPositions (data) {
  return request({
    url: '/ooh-product/v1/resource/getresourcepositions',
    method: 'POST',
    data: qs.stringify(data)
  })
}

// 资源分页数据
export function getLmResourcePage (data) {
  return request({
    url: '/ooh-product/v1/resource/getlmresourcepage',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 修改资源状态
export function updateResourceStatus (data) {
  return request({
    url: '/ooh-product/v1/resource/updateresourcestatus',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 资源状态历史数据
export function getResourceHistoryPage (data) {
  return request({
    url: '/ooh-product/v1/resource/getresourcehistorypage',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取设备故障统计
export function getTerminalStatistic (data) {
  return request({
    url: '/ooh-product/v1/resource/getterminalstatistic',
    method: 'POST',
    data: qs.stringify(data)
  })
}

// 设备分页数据
export function getTerminalList (data) {
  return request({
    url: '/ooh-product/v1/resource/getterminallist',
    method: 'POST',
    data: qs.stringify(data)
  })
}

// 获取对应的材质工艺可选项
export function getMakeinfoList (data) {
  return request({
    url: '/ooh-product/v1/resource/getmakeinfolist',
    method: 'POST',
    data: qs.stringify(data)
  })
}

// 新增媒介资源
export function saveResource (data) {
  return request({
    url: '/ooh-product/v1/resourceedit/saveresource',
    method: 'POST',
    data: qs.stringify(data)
  })
}

// 修改媒介资源
export function updateResource (data) {
  return request({
    url: '/ooh-product/v1/resourceedit/updateResource',
    method: 'POST',
    data: qs.stringify(data)
  })
}

// 资源示例图
export function getResourceImg (data) {
  return request({
    url: '/ooh-product/v1/resource/getresourceimg',
    method: 'POST',
    data: qs.stringify(data)
  })
}

// 获取资源类型
export function getResourceTypes (data) {
  return request({
    url: '/ooh-product/v1/resourceedit/getresourcetypes',
    method: 'POST',
    data: qs.stringify(data)
  })
}

/**
 * 新增或修改
 * @param {Object} data
 * @returns
 */
export function submit (data) {
  return request.post('/ooh-product/v1/resource/submit', data, {
    headers: {
      'content-type': 'application/json'
    }
  })
}

/**
 * 资源分页数据
 * @param {Object} data
 * @returns
 */
export function getResourcePage (data) {
  return request.post('/ooh-product/v1/resource/getresourcepage', qs.stringify(data))
}

/**
 * 删除资源
 * @param {Object} data
 * @returns
 */
export function deleteResource (data) {
  return request.post('/ooh-product/v1/resource/delete', qs.stringify(data))
}

/**
 * 冻结/解冻资源
 * @param {Object} data
 * @returns
 */
export function freezeResource (data) {
  return request.post('/ooh-product/v1/resource/freeze', qs.stringify(data))
}

/**
 * 批量开放二级市场
 * @param {Object} data
 * @returns
 */
export function setOpen (data) {
  return request.post('/ooh-product/v1/resource/setOpen', data, {
    headers: {
      'content-type': 'application/json'
    }
  })
}

/**
 * 导入excel资源清单
 * @param param FormData
 * @returns ResponseDTO
 */
export function imporResourcetExcel (param) {
  return request.post('/ooh-product/v1/resource/importexcel', param, { timeout: 60000 })
}
