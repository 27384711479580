import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取租户的设备分页
 * @param {Object} data
 */
export function getPublisherDevicePage (params) {
  return request.get('/ooh-rim/v1/device/getpublisherdevicepage', { params })
}

/**
 * 获取设备状态集合
 */
export function getStatusList () {
  return request.get('/ooh-rim/v1/device/getstatuslist')
}

/**
 * 新增或修改
 */
export function submit (data) {
  return request.post('/ooh-rim/v1/device/submit', data, {
    headers: {
      'content-type': 'application/json'
    }
  })
}

/**
 * 发起导出设备列表
 */
export function startExportDevice (params) {
  return request.get('/ooh-rim/v1/device/startexportdevice', { params })
}

/**
 * 下载设备列表
 */
export function downloadExcel (params) {
  return request.get('/ooh-rim/v1/device/downloadexcel', { params })
}

/**
 * 获取公司可见的设备分页
 */
export function getCompanyDevicePage (params) {
  return request.get('/ooh-rim/v1/device/getcompanydevicepage', { params })
}

/**
 * 获取设备状态统计
 */
export function getStatusQuantity (params) {
  return request.get('/ooh-rim/v1/device/getstatusquantity', { params })
}

/**
 * 获取设备详情
 */
export function getDevice (params) {
  return request.get('/ooh-rim/v1/device/getdevice', { params })
}

/**
 * 获取设备历史状态记录
 */
export function getHistoryList (params) {
  return request.get('/ooh-rim/v1/device/gethistorylist', { params })
}

/**
 * 修改设备状态
 */
export function updateStatus (data) {
  return request.post('/ooh-rim/v1/device/updatestatus', qs.stringify(data))
}

/**
 * 获取设备的设备小类型统计
 */
export function getDeviceModelQuantity (params) {
  return request.get('/ooh-rim/v1/device/getdevicemodelquantity', { params })
}

/**
 * 获取设备的位置统计
 */
export function getPositionQuantity (params) {
  return request.get('/ooh-rim/v1/device/getpositionquantity', { params })
}

/**
 * 获取租户的设备二级类型集合
 */
export function getPublisherDeviceModelList (params) {
  return request.get('/ooh-rim/v1/device/getpublisher-devicemodellist', { params })
}

/**
 * 导入excel设备清单
 * @param param FormData
 * @returns ResponseDTO
 */
export function importExcel (param) {
  return request.post('/ooh-rim/v1/device/importexcel', param, { timeout: 60000 })
}

/**
 * 商业地产实体资产分页数据
 * @param param query
 * @returns
 */
export function getEstateDevicePage (params) {
  return request.get('/ooh-rim/v1/device/getestate-devicepage', { params })
}

/**
 * 商业地产实体资产分页数据（不含租户信息）
 * @param param query
 * @returns
 */
export function getEstateDevicePage2 (params) {
  return request.get('/ooh-rim/v1/device/getestate-devicepage2', { params })
}

/**
 * 获取站点下的楼层
 * @param param query
 * @returns
 */
export function getFloor (params) {
  return request.get('/ooh-rim/v1/device/getfloor', { params })
}

/**
 * 获取设备租赁记录
 * @param param query
 * @returns
 */
export function getLeaseList (params) {
  return request.get('/ooh-rim/v1/device/getleaselist', { params })
}

/**
 * 新增设备租赁记录
 * @param param FormData
 * @returns ResponseDTO
 */
export function addLease (data) {
  return request.post('/ooh-rim/v1/device/addlease', qs.stringify(data))
}

/**
 * 获取站点下设备数量
 * @param param query
 * @returns
 */
export function getStationQuantity (params) {
  return request.get('/ooh-rim/v1/device/getstationquantity', { params })
}

/**
 * 作废设备租赁记录
 * @param param FormData
 */
export function disableLease (data) {
  return request.post('/ooh-rim/v1/device/disablelease', qs.stringify(data))
}

/**
 * 获取设备图片
 * @param param query
 * @returns
 */
export function getDevicePhoto (params) {
  return request.get('/ooh-rim/v1/device/getDevicePhoto', { params })
}

/**
 * 设置设备图片
 * @param param FormData
 */
export function setDevicePhoto (data) {
  return request.post('/ooh-rim/v1/device/setDevicePhoto', qs.stringify(data))
}
