<template>
    <div class="p-t-5">
        <!-- 显示城市分布 -->
        <Row class="p-b-5">
            <i-col span="24" class="p-r-10">
                城市分布
                <a class="m-l-5" v-for="city in cityCount"
                  :class="(chooseCity===city.cityCode)?'choose-active':''" :key="city.cityCode " @click="handleChangeCityCode(city)">
                    {{city.cityName}}({{city.quantity }})
                </a>
            </i-col>
        </Row>
        <Row class="p-b-5">
            <i-col span="24" class="p-r-10">
                线路分布
                <a class="m-l-5" v-for="line in lineCount.filter(x=>x.cityCode===chooseCity||chooseCity===null)"
                  :class="(chooseLine===line.assetId)?'choose-active':''" :key="line.assetId " @click="handleChangeLine(line)">
                    {{line.assetName}}({{line.quantity }})
                </a>
            </i-col>
        </Row>
        <Row class="p-b-5">
            <i-col span="24" class="p-r-10">
                站点分布
                <a class="m-l-5" v-for="station in stationCount.filter(x=>(x.cityCode===chooseCity&&chooseLine===null )||(x.assetId===chooseLine)||(chooseCity===null&&chooseLine===null))"
                  :key="station.stationId " @click="handleChangeStation(station)">
                    {{station.stationName}}({{station.quantity }})
                </a>
            </i-col>
        </Row>

        <Row :gutter="8" class="p-b-5">
            <i-col span="4">
              <Button type="error" v-if="!customskuId" size="small" long :disabled="selection.length<1" @click="handleDeleteShow()">批量删除</Button>
            </i-col>
            <i-col span="16">
              <i-input size="small" v-model="query.keyword" placeholder="输入查询关键字：站点、编号等"></i-input>
            </i-col>
            <i-col span="4">
              <Button icon="ios-search" size="small" type="primary" @click="handleSearch">搜索</Button>
            </i-col>
        </Row>

        <Row class="table-title p-t-2">
            <i-col span="2">
                <Checkbox style="margin-top: -3px" size="small" v-model="checkAllResource" @on-change="handlCheckAllResource"></Checkbox>
            </i-col>
            <i-col span="3">编号</i-col>
            <i-col span="3">站台</i-col>
            <i-col span="3">资产</i-col>
            <i-col span="2">楼层</i-col>
            <i-col span="2">出口</i-col>
            <i-col span="3">位置</i-col>
            <i-col span="3">价格</i-col>
            <i-col span="3">操作</i-col>
        </Row>
        <Row v-for="(resource,index) in resourceList" :key="index" class="p-t-2" :class="computedRowClassName(resource,index)">
            <i-col span="2">
                <Checkbox style="margin-top: -3px" size="small" v-model="resource.isChecked" @on-change="handleCheckOnlyResource"></Checkbox>
            </i-col>
            <i-col span="3">{{resource.resourceCode}}</i-col>
            <i-col span="3">{{resource.stationName}}</i-col>
            <i-col span="3">{{resource.assetName}}</i-col>
            <i-col span="2">{{resource.floor}}</i-col>
            <i-col span="2">{{resource.exit===''?'-':resource.exit}}</i-col>
            <i-col span="3">{{resource.positionName}}</i-col>
            <i-col span="3">
              {{formatMoney(resource.useprice)}}
              <span v-if="resource.duration>1">({{resource.duration}} 秒)</span>
            </i-col>
            <i-col span="3">
                <a @click="handleReplaceResource(resource)">替换</a>
                <a class="m-l-5 delete" v-if="!customskuId" @click="handleDeleteShow(resource)">删除</a>
            </i-col>
        </Row>
        <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" :current="query.pageNumber" show-total show-elevator @on-change="handlePageNumberChange"></Page>
        </div>

        <Modal v-model="showReplace" width="1000" title="资源替换" :footer-hide="true">
            <replaceMetro  :params="toReplace"  v-if="showReplace" @on-update="handleReplaced"></replaceMetro>
        </Modal>

        <!-- 弹窗显示点位的站点分布情况 -->
        <SvgStation ref="svgStation" :stationSvgModal.sync="showStation" :orderId="params.orderId" :stationId="currentStation.stationId"
          :customskuId="customskuId" :searchSchedules="[{startDate: params.startDate, endDate: params.endDate}]" :svgShowType="3"
          showStationPortray
          @svg-finished="svgFinished" @handleChange3dMode="handleBeginRevice3d" />

        <ThreeStation ref="threeStation" @three-finished="threeFinished" @handleChangeSvgMode="handleClickStation" />
    </div>
</template>

<script>
import replaceMetro from '../components/ReplaceMetro'
import SvgStation from '@/components/svg/SvgStation'
import ThreeStation from '@/components/svg/ThreeStation'

import { toMoney } from '@/utils/wnumb_own'

import { getProductDetailPageByOrder, getResourceCountByOrderId } from '@/api/order/productdetail'
import { deleteOrderItem } from '@/api/order/orderitem'

export default {
  props: {
    params: Object,
    customskuId: {
      type: Number,
      default: null
    }
  },
  components: {
    replaceMetro, SvgStation, ThreeStation
  },
  data () {
    return {
      query: { pageNumber: 1, pageSize: 10, keyword: '' },
      resourceList: [],
      total: 0,
      cityCount: [], // 城市分布
      chooseCity: null,
      lineCount: [], // 线路分布
      chooseLine: null,

      stationCount: [], // 站点分布
      selection: [],
      showReplace: false,
      toReplace: {},
      showStation: false,
      currentStation: {
        stationId: 0
      },

      checkAllResource: false
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    computedRowClassName (row, index) {
      if (row.conflict) {
        return 'table-conflict-row'
      } else if (index % 2 === 0) {
        return 'table-row-1'
      } else {
        return 'table-row-2'
      }
    },
    initPageData () {
      this.initPurchaseList()
      this.getStationCount()
    },
    initPurchaseList () {
      this.query.orderId = this.params.orderId
      this.query.productId = this.params.productId
      this.query.startDate = this.params.startDate
      this.query.endDate = this.params.endDate
      this.query.priceType = this.params.priceType
      this.query.customskuId = this.customskuId || undefined

      this.query.assetIds = JSON.stringify(this.lineCount.filter(x => x.cityCode === this.chooseCity || this.chooseCity === null).map(x => x.assetId))
      this.query.assetId = this.chooseLine

      getProductDetailPageByOrder(this.query).then(res => {
        this.total = res.totalRow
        this.resourceList = res.list.map(x => {
          x.isChecked = false
          return x
        })
      })
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.initPurchaseList()
    },
    getStationCount () {
      const stationQuery = {
        orderId: this.params.orderId,
        productId: this.params.productId,
        customskuId: this.customskuId || undefined,
        schedules: JSON.stringify([{ startDate: this.params.startDate, endDate: this.params.endDate }]),
        priceType: this.params.priceType
      }
      getResourceCountByOrderId(stationQuery).then(res => {
        this.cityCount = []
        this.lineCount = []
        this.stationCount = res

        let cityBean = null
        let lineBean = null
        res.forEach(resource => {
          // 城市分布
          cityBean = this.cityCount.find(x => x.cityCode === resource.cityCode)
          if (!cityBean) {
            this.cityCount.push({
              cityCode: resource.cityCode,
              cityName: resource.cityName,
              quantity: resource.quantity
            })
          } else {
            cityBean.quantity += resource.quantity
          }
          // 线路分布
          lineBean = this.lineCount.find(x => x.assetId === resource.assetId)
          if (!lineBean) {
            this.lineCount.push({
              cityCode: resource.cityCode,
              cityName: resource.cityName,
              assetName: resource.assetName,
              assetId: resource.assetId,
              quantity: resource.quantity
            })
          } else {
            lineBean.quantity += resource.quantity
          }
        })

        this.$emit('on-update-map', res, this.cityCount)
      })
    },
    handleChangeCityCode (city) {
      if (this.chooseCity === city.cityCode || city === '') {
        this.chooseCity = null
      } else {
        this.chooseCity = city.cityCode
      }

      this.chooseLine = null
      this.query.pageNumber = 1
      if (city !== '') {
        this.initPurchaseList()
      }

      let allowAssetIds = [this.chooseLine]
      if (this.chooseLine === null) {
        allowAssetIds = this.lineCount.filter(x => x.cityCode === this.chooseCity || this.chooseCity === null).map(x => x.assetId)
      }
      this.$emit('on-update-city', this.chooseCity, allowAssetIds)
    },
    handleChangeLine (line) {
      this.chooseLine = (this.chooseLine === line.assetId) ? null : line.assetId
      this.query.pageNumber = 1
      this.initPurchaseList()

      let currentChooseCity = this.chooseCity
      if (!currentChooseCity) {
        currentChooseCity = line.cityCode
      }

      let allowAssetIds = [this.chooseLine]
      if (this.chooseLine === null) {
        allowAssetIds = this.lineCount.filter(x => x.cityCode === this.chooseCity || this.chooseCity === null).map(x => x.assetId)
      }
      this.$emit('on-update-city', currentChooseCity, allowAssetIds)
    },
    handleChangeStation (station) {
      this.currentStation = station
      this.showStation = true
    },
    svgFinished () {
      this.$refs.svgStation.initShowBrand()

      const schedule = JSON.stringify([{ startDate: this.params.startDate, endDate: this.params.endDate }])
      const resourceSearchData = {
        orderId: this.params.orderId,
        stationIds: this.currentStation.stationId,
        schedules: schedule,
        productId: this.params.productId,
        customskuId: this.customskuId,
        pageSize: 200,
        pageNumber: 1
      }

      getProductDetailPageByOrder(resourceSearchData).then(res => {
        this.$refs.svgStation.tagResource(res.list)
        this.$refs.svgStation.choseSpinShow()
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.currentLevel = null
      this.initPageData()
    },
    handleDeleteShow (resource) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '点击确定后资源将会从您订单里删除，请谨慎操作',
        onOk: () => {
          this.handleDelete(resource)
        }
      })
    },
    handleDelete (resource) {
      const data = {
        orderId: this.params.orderId,
        orderitemIds: resource ? JSON.stringify([resource.orderitemId]) : JSON.stringify(this.selection.map(s => s.orderitemId))
      }

      deleteOrderItem(data).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '删除成功' })
          this.$emit('on-update-order', this.params.categoryId)

          if (!resource) { this.selection = [] }
        }
      })
    },
    handleReplaceResource (resource) {
      this.toReplace = Object.assign({}, resource, { orderId: this.params.orderId, categoryId: this.params.categoryId })
      this.showReplace = true
    },
    handleReplaced () {
      this.showReplace = false
      this.$emit('on-update-order', this.params.categoryId)
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handlCheckAllResource (val) {
      this.selection = []
      this.resourceList.forEach(element => {
        element.isChecked = val
        if (val) {
          this.selection.push(element)
        }
      })
    },
    handleCheckOnlyResource () {
      this.selection = []
      this.resourceList.forEach(element => {
        if (element.isChecked) {
          this.selection.push(element)
        }
      })
    },
    handleBeginRevice3d (stationId) {
      this.$refs.threeStation.initData(stationId)
    }
  },
  watch: {
    params (val) {
      this.query.pageNumber = 1
      this.checkAllResource = false
      this.initPageData()
    }
  }
}
</script>
