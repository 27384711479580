import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 清洁按站点分组的资源数量
 * @param {Object} data
 */
export function getCleanTaskStation (data) {
  return request({
    url: '/ooh-product/v1/station/getcleantaskstation',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 指定距离内获取满足关键字的站台
export function getStationsByKeyword (data) {
  return request({
    url: '/ooh-product/v1/station/getstationsbykeyword',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取资产下产品的站点集合
export function getStationByAsset (data) {
  return request({
    url: '/ooh-product/v1/station/getstationbyasset',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 多条资产的站点中心坐标
export function getStationCoreCoordinate (data) {
  return request({
    url: '/ooh-product/v1/station/getstationcorecoordinate',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取资产下的站点集合
export function getStationByAssetIds (data) {
  return request({
    url: '/ooh-product/v1/station/getstationbyassetIds',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 中心站点坐标
export function getCenterStationCoordinate (data) {
  return request({
    url: '/ooh-product/v1/station/getcenterstationcoordinate',
    method: 'POST',
    data: qs.stringify(data)
  })
}

// 站点列表
export function getStationPage (data) {
  return request({
    url: '/ooh-product/v1/station/getstationpage',
    method: 'POST',
    data: qs.stringify(data)
  })
}
