<template>
  <div>
     <Row :gutter="8" class="p-b-10">
        <i-col span="5">
          <i-select size="small" v-model="currentAsset" placeholder="选择资产" @on-change="handleChangeAsset">
            <i-option v-for="asset in assets" :key="asset.id" :value="asset.id">{{asset.name}}</i-option>
          </i-select>
        </i-col>
        <i-col span="5">
            <i-select size="small" v-model="currentStation" placeholder="请选择站台" clearable>
            <i-option v-for="station in stations" :key="station.id" :value="station.id">{{station.name}}</i-option>
          </i-select>
        </i-col>
        <i-col span="5">
          <i-select size="small" placeholder="请选择位置" v-model="currentPosition" clearable>
            <i-option v-for="position in positions" :key="position.value" :value="position.value">{{position.name}}</i-option>
          </i-select>
        </i-col>
        <i-col span="5">
            <i-input size="small" placeholder="资源编号/站点名称" v-model='query.keyword'></i-input>
        </i-col>
        <i-col span="4">
            <Button size="small" type="primary" icon="ios-search" @click="handleSearch">搜索</Button>
        </i-col>
      </Row>
      <Table size="small" :columns="columns"  :data="data" stripe></Table>
      <div class="paging_style">
        <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator  @on-change="handlePageNumberChange"></Page>
      </div>

      <Spin fix size="large" v-if="spinShow">
        <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
        <div>资源生成中...</div>
      </Spin>
  </div>
</template>

<script>
import { replaceResource } from '@/api/order/purchase'
import { getProductResourcePage } from '@/api/product/productresource'
import { getStationByAsset } from '@/api/product/station'
import { getPositions } from '@/api/product/resource'
import { assetList } from '@/api/product/category'

export default {
  props: {
    params: {
      type: Object
    }
  },
  data () {
    return {
      spinShow: false,
      columns: [
        { title: '资源编号', key: 'code' },
        { title: '资源等级', width: 90, key: 'stationLevelName' },
        { title: '资源类型', key: 'resourcetypeName' },
        { title: '所属站台', key: 'stationName' },
        { title: '所属楼层', width: 90, key: 'floor', mediaType: 0 },
        { title: '位置', width: 90, key: 'positionName' },
        {
          title: '操作',
          align: 'center',
          width: 100,
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.ConfirmReplaceResource(params.row)
                }
              }
            }, '替换')
          }
        }
      ],
      data: [],
      total: 0,
      query: { keyword: '', pageNumber: 1, pageSize: 10, sold: 0 },
      assets: [],
      stations: [],
      positions: [],
      currentAsset: 0,
      currentStation: 0,
      stationMap: new Map(),
      currentPosition: -1
    }
  },
  methods: {
    initPageData () {
      // 加载可用的城市、线路
      const assetquery = { categoryId: this.params.categoryId, publisherId: this.$store.getters.token.userInfo.publisherId, brandId: this.orderBean.brandId }
      assetList(assetquery).then(res => {
        this.assets = res
        this.LoadStations()
        this.getPositions()
      }).then(() => {
        this.getPage()
      })
    },
    ConfirmReplaceResource (item) {
      this.spinShow = true
      const data = {
        oldresourceId: this.params.resourceId,
        oldStartDate: this.params.startDate,
        oldEndDate: this.params.endDate,
        newresourceId: item.resourceId,
        orderId: this.params.orderId
      }
      replaceResource(data).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '替换成功' })
          this.$emit('on-update')
        }
        this.spinShow = false
      })
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.getPage()
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.getPage()
    },
    getPage () {
      this.query.assetIds = JSON.stringify([this.currentAsset])
      this.query.stationId = (this.currentStation > 0) ? this.currentStation : null
      this.query.position = (this.currentPosition > -1) ? this.currentPosition : null

      this.query.enabled = 1
      this.query.excludeOrderId = this.params.orderId
      this.query.productId = this.params.productId // 暂时屏蔽掉类型限制
      this.query.schedules = JSON.stringify([{ startDate: this.params.startDate, endDate: this.params.endDate }])
      getProductResourcePage(this.query).then(res => {
        this.total = res.totalRow
        this.data = res.list
      })
    },
    LoadStations () {
      if (this.stationMap.has(this.currentAsset)) {
        this.stations = this.stationMap.get(this.currentAsset)
      } else {
        getStationByAsset({ assetId: this.currentAsset }).then(res => {
          this.stations = res
          this.stationMap.set(this.currentAsset, res)
        })
      }
    },
    getPositions () {
      const query = {
        assetIds: JSON.stringify([this.currentAsset])
      }
      getPositions(query).then(res => {
        this.positions = res
      })
    },
    handleChangeAsset () {
      this.currentStation = 0
      this.currentPosition = -1
      this.LoadStations()
      this.getPage()
    }
  },
  created () {
    this.initPageData()
    this.currentAsset = this.params.assetId
  },
  computed: {
    orderBean () {
      return this.$store.state.adx.orderBean
    }
  }

}
</script>
